import styled from "@emotion/styled";
import mq from "@styles/media";
import tw from "twin.macro";
import { commonStyles } from "@styles/common";

const UnorderedList = styled.ul`
    ${tw`list-none relative py-4 pl-8 m-0 mx-auto w-full`};
    counter-reset: list;
    transition: ${p => p.theme.colorModeTransition};

    li {
        ${tw`relative pb-4`};
    }

    li > :not(ol, ul) {
        ${tw`inline`};
    }

    li::before {
        ${tw`absolute -left-7 h-2 w-2 bg-gray-900`};
        top: 10px;
        content: "";
    }
    
    ${commonStyles};
`;

export default UnorderedList;
