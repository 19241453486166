import styled from "@emotion/styled";
import mq from "@styles/media";
import tw from "twin.macro";
import { commonStyles } from "@styles/common";

const OrderedList = styled.ol<{noCounter?: boolean}>`
    ${tw`list-none relative py-4 pl-8 m-0 mx-auto w-full`};
    counter-reset: list;
    transition: ${p => p.theme.colorModeTransition};
    
    li {
        ${tw`relative pb-4`};
    }

    li > :not(ol, ul) {
        ${tw`inline`};
    }

    & > li::before {
        ${tw`absolute -left-7 h-8 w-8 text-gray-900 inline-block`};
        ${p => !p.noCounter && `
            counter-increment: list;
            content: counter(list) ".";
        `};
        ${p => !p.noCounter && tw`font-bold top-0 text-lg`};
    }
    ${commonStyles};
`;

export default OrderedList;
