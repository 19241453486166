import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import mq from "@styles/media";
import tw from "twin.macro";

import SEO from "../components/SEO/SEO";
import Layout from "../components/Layout/Layout";
import Transitions from "../components/Transitions";
import Wrapper from "../components/Wrapper";
import Section from "../components/Section";
import Anchor from "../components/Anchor";
import Lists from "../components/Lists";

import NotFoundSVG from "@images/svg/notfound.inline.svg";

const NotFoundPage: React.FC<{}> = () => (
    <Layout>
        <SEO slug="/adatvedelem" noIndex={true} isOrganization title="Adatvédelmi Szabályzat" />
        <Transitions.CSS.FadeIn as={Section}>
                <Wrapper>
                    <Transitions.CSS.FadeInUp as={`div`}>
                        <Title><span>Adatvédelmi Szabályzat</span></Title>
                        {/* <Text>Oh-oh! You just hit a route that doesn't exist...</Text> */}
                    </Transitions.CSS.FadeInUp>
                    <TextSection>
                        <PrivacyTitle>Bevezetés</PrivacyTitle>
                        <Text>
                        A <b>HG PRISMA Korlátolt Felelősségű Társaság</b> (a továbbiakban: Szolgáltató, adatkezelő) alá veti magát a következő szabályzatnak: <br />
                        A természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról,
                        valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet) AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU)
                        2016/679 RENDELETE (2016. április 27.) szerint az alábbi tájékoztatást adjuk.<br />
                        Jelen adatvédelmi szabályzat az alábbi oldalak adatkezelését szabályozza: <br />
                        - <Anchor as={Link} to="/">https://www.ifaktoragency.hu</Anchor><br />
                        Az adatvédelmi szabályzat elérhető az alábbi oldalról:<br />
                        - <Anchor as={Link} to="/adatvedelem">https://www.ifaktoragency.hu/adatvedelem</Anchor><br />
                        A szabályzat módosításai a fenti címen történő közzététellel lépnek hatályba.
                        </Text>

                        
                        <SmallPrivacyTitle>1.1. Az adatkezelő és elérhetősége:</SmallPrivacyTitle>
                        <Text>
                        Név: HG PRISMA Korlátolt Felelősségű Társaság<br />
                        Székhely: 1024 Budapest, Margit körút 5. A. ép. 3. em. 1.<br />
                        E-mail: adatkezeles@ifaktor.hu <br />
                        Telefon: +36 20 516 9448
                        </Text>

                        <SmallPrivacyTitle>1.2. Az adatvédelmi tisztviselő elérhetőségei:</SmallPrivacyTitle>
                        <Text>
                        Az adatkezelő nyilatkozik, hogy a GDPR 37. cikk 1 bekezdésében foglalt feltételek nem állnak fönn, így adatvédelmi tisztviselő kinevezésére nem kerül sor.
                        </Text>

                        <PrivacyTitle>2. Fogalom Meghatározások</PrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>„személyes adat”: azonosított vagy azonosítható természetes személyre („érintett”) vonatkozó bármely információ; azonosítható az a természetes személy, aki közvetlen vagy közvetett módon, különösen valamely azonosító, például név, szám, helymeghatározó adat, online azonosító vagy a természetes személy testi, fiziológiai, genetikai, szellemi, gazdasági, kulturális vagy szociális azonosságára vonatkozó egy vagy több tényező alapján azonosítható;</li>
                                <li>„adatkezelés”: a személyes adatokon vagy adatállományokon automatizált vagy nem automatizált módon végzett bármely művelet vagy műveletek összessége, így a gyűjtés, rögzítés, rendszerezés, tagolás, tárolás, átalakítás vagy megváltoztatás, lekérdezés, betekintés, felhasználás, közlés továbbítás, terjesztés vagy egyéb módon történő hozzáférhetővé tétel útján, összehangolás vagy összekapcsolás, korlátozás, törlés, illetve megsemmisítés;</li>
                                <li>„adatkezelő”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely a személyes adatok kezelésének céljait és eszközeit önállóan vagy másokkal együtt meghatározza; ha az adatkezelés céljait és eszközeit az uniós vagy a tagállami jog határozza meg, az adatkezelőt vagy az adatkezelő kijelölésére vonatkozó különös szempontokat az uniós vagy a tagállami jog is meghatározhatja;</li>
                                <li>„adatfeldolgozó”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, amely az adatkezelő nevében személyes adatokat kezel;</li>
                                <li>„címzett”: az a természetes vagy jogi személy, közhatalmi szerv, ügynökség vagy bármely egyéb szerv, akivel vagy amellyel a személyes adatot közlik, függetlenül attól, hogy harmadik fél-e. Azon közhatalmi szervek, amelyek egy egyedi vizsgálat keretében az uniós vagy a tagállami joggal összhangban férhetnek hozzá személyes adatokhoz, nem minősülnek címzettnek; az említett adatok e közhatalmi szervek általi kezelése meg kell, hogy feleljen az adatkezelés céljainak megfelelően az alkalmazandó adatvédelmi szabályoknak;</li>
                                <li>„az érintett hozzájárulása”: az érintett akaratának önkéntes, konkrét és megfelelő tájékoztatáson alapuló és egyértelmű kinyilvánítása, amellyel az érintett nyilatkozat vagy a megerősítést félreérthetetlenül kifejező cselekedet útján jelzi, hogy beleegyezését adja az őt érintő személyes adatok kezeléséhez;</li>
                                <li>„adatvédelmi incidens”: a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi.</li>
                            </Lists.ol>
                        </Text>

                        <PrivacyTitle>3. A Személyes Adatok Kezelésére Vonatkozó Elvek</PrivacyTitle>
                        <Text>
                        A személyes adatok:
                            <Lists.ol noCounter={true}>
                                <li><b>a)</b>	kezelését jogszerűen és tisztességesen, valamint az érintett számára átlátható módon kell végezni („jogszerűség, tisztességes eljárás és átláthatóság”);</li>
                                <li><b>b)</b>	gyűjtése csak meghatározott, egyértelmű és jogszerű célból történjen, és azokat ne kezeljék ezekkel a célokkal össze nem egyeztethető módon; a 89. cikk (1) bekezdésének megfelelően nem minősül az eredeti céllal össze nem egyeztethetőnek a közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból történő további adatkezelés („célhoz kötöttség”);</li>
                                <li><b>c)</b>	az adatkezelés céljai szempontjából megfelelőek és relevánsak kell, hogy legyenek, és a szükségesre kell korlátozódniuk („adattakarékosság”);</li>
                                <li><b>d)</b>	pontosnak és szükség esetén naprakésznek kell lenniük; minden észszerű intézkedést meg kell tenni annak érdekében, hogy az adatkezelés céljai szempontjából pontatlan személyes adatokat haladéktalanul töröljék vagy helyesbítsék („pontosság”);</li>
                                <li><b>e)</b>	tárolásának olyan formában kell történnie, amely az érintettek azonosítását csak a személyes adatok kezelése céljainak eléréséhez szükséges ideig teszi lehetővé; a személyes adatok ennél hosszabb ideig történő tárolására csak akkor kerülhet sor, amennyiben a személyes adatok kezelésére a 89. cikk (1) bekezdésének megfelelően közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból kerül majd sor, az e rendeletben az érintettek jogainak és szabadságainak védelme érdekében előírt megfelelő technikai és szervezési intézkedések végrehajtására is figyelemmel („korlátozott tárolhatóság”);</li>
                                <li><b>f)</b>	kezelését oly módon kell végezni, hogy megfelelő technikai vagy szervezési intézkedések alkalmazásával biztosítva legyen a személyes adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy károsodásával szembeni védelmet is ideértve („integritás és bizalmas jelleg”).</li>
                            </Lists.ol>
                        Az adatkezelő felelős a fentiek megfelelésért, továbbá képesnek kell lennie e megfelelés igazolására („elszámoltathatóság”).<br />
                        Az adatkezelő nyilatkozik, hogy adatkezelése a jelen pontban foglalt alapelveknek megfelelően történik.
                        </Text>

                        <PrivacyTitle>4. Egyes Adatkezelések</PrivacyTitle>
                        <SmallPrivacyTitle>4.1. Regisztráció:</SmallPrivacyTitle>
                        <Text>
                        <Lists.ol>
                            <li>Az adatgyűjtés ténye, a kezelt adatok köre és az adatkezelés céljai:<br />
                                <Table>
                                    <thead>
                                        <tr>
                                            <ThCol>Személyes adat</ThCol>
                                            <ThCol>Az adatkezelés célja</ThCol>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Tcol>Vezetéknév, keresztnév</Tcol>
                                            <Tcol>Azonosítás, a felhasználói fiókba történő biztonságos belépést szolgálja.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>E-mail cím</Tcol>
                                            <Tcol>Kapcsolattartás, rendszerüzenetek küldése, felhasználói fiókba történő belépés</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Értesítési telefonszám</Tcol>
                                            <Tcol>Azonosítás, kapcsolattartás</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Számlázási név és cím</Tcol>
                                            <Tcol>A szabályszerű számla kiállítása, továbbá a szerződés létrehozása, tartalmának meghatározása, módosítása, teljesítésének figyelemmel kísérése, az abból származó díjak számlázása, valamint az azzal kapcsolatos követelések érvényesítése.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Szállítási név és cím</Tcol>
                                            <Tcol>A házhoz szállítás lehetővé tétele.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Kiegészítő adatok</Tcol>
                                            <Tcol>A szolgáltatás teljesítésének hatékonyabbá tétele</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>A regisztráció időpontja</Tcol>
                                            <Tcol>Technikai művelet végrehajtása.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>A regisztráció kori IP cím</Tcol>
                                            <Tcol>Technikai művelet végrehajtása.</Tcol>
                                        </tr>
                                    </tbody>
                                </Table><br/>
                                Az e-mail cím esetében nem szükséges, hogy személyes adatot tartalmazzon. 
                            </li>
                            <li><b>Az érintettek köre:</b> Az oldalra regisztrált felhasználók köre.</li>
                            <li><b>Az adatkezelés időtartama, az adatok törlésének határideje:</b> Ha a GDPR 17. cikk (1)	bekezdésében foglalt feltételek valamelyike fennáll, úgy az érintett törlési kérelméig tart. A regisztráció törlésével azonnal törlődnek a személyes adatok. Az érintett által megadott bármely személyes adat törléséről az adatkezelő a GDPR 19. cikke alapján, elektronikus úton tájékoztatja az érintettet. Ha az érintett törlési kérelme kiterjed az általa megadott e-mail címre is, akkor az adatkezelő a tájékoztatást követően az e-mail címet is törli.</li>
                            <li><b>Az adatok megismerésére jogosult lehetséges adatkezelők személye, a személyes adatok címzettjei:</b> A személyes adatokat az adatkezelőnek az erre felhatalmazott munkatársai kezelhetik a jelen tájékoztatóban foglaltak alapján.</li>
                            <li><b>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése:</b><br />
                                <Lists.ul>
                                    <li>Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, és</li>
                                    <li>az érintettnek joga van az adathordozhatósághoz, továbbá a hozzájárulás bármely időpontban történő visszavonásához.</li>
                                </Lists.ul>
                            </li>
                            <li><b>A személyes adatokhoz való hozzáférést, azok törlését, módosítását, vagy kezelésének korlátozását, az adatok hordozhatóságát az alábbi módokon tudja érintett kezdeményezni:</b><br />
                            - postai úton a 1024 Budapest, Margit körút 5. A. ép. 3. em. 1,<br />
                            - e-mail útján az adatkezeles@ifaktor.hu e-mail címen,<br />
                            - telefonon a +36 20 516 9448 számon.
                            </li>
                            <li><b>Az adatkezelés jogalapja:</b> 6. cikk (1) bekezdés a) és b) pont.</li>
                            <li>Tájékoztatjuk, hogy<br />
                                <Lists.ul>
                                    <li>az adatkezelés az Ön hozzájárulásán és/vagy a szerződés megkötését megelőzően az Ön kérésére történő lépések megtételéhez szükséges.</li>
                                    <li>köteles a személyes adatokat megadni, hogy regisztrálni tudjuk.</li>
                                    <li>az adatszolgáltatás elmaradása azzal a következménnyel jár, hogy a felhasználói fiókot nem tudjuk létre hozni</li>
                                </Lists.ul>
                            </li>
                            </Lists.ol>
                        </Text>

                        <SmallPrivacyTitle>4.2. Hírlevél, DM Tevékenység:</SmallPrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>A gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény 6. §-a értelmében Felhasználó előzetesen és kifejezetten hozzájárulhat ahhoz, hogy Szolgáltató reklámajánlataival, ajánlataival, egyéb küldeményeivel a regisztrációkor megadott elérhetőségein megkeresse.</li>
                                <li>Továbbá Ügyfél a jelen tájékoztató rendelkezéseit szem előtt tartva hozzájárulhat ahhoz, hogy Szolgáltató a reklámajánlatok, egyéb ajánlatok küldéséhez szükséges személyes adatait kezelje.  </li>
                                <li>Szolgáltató nem küld kéretlen reklámüzenetet, és Felhasználó korlátozás és indokolás nélkül, ingyenesen leiratkozhat az ajánlatok küldéséről. Ebben az esetben Szolgáltató minden - a reklámüzenetek küldéséhez szükséges - személyes adatát törli nyilvántartásából és további reklámajánlataival, egyéb ajánlataival nem keresi meg a Felhasználót. Felhasználó a reklámokról leiratkozhat az üzenetben lévő linkre kattintva, valamint a hírlevél láblécében megjelenített valamennyi elérhetőség bármelyikén.</li>
                                <li> Az adatgyűjtés ténye, a kezelt adatok köre és az adatkezelés célja:<br />
                                <Table>
                                    <thead>
                                        <tr>
                                            <ThCol>Személyes adat</ThCol>
                                            <ThCol>Az adatkezelés célja</ThCol>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Tcol>Vezetéknév, keresztnév</Tcol>
                                            <Tcol>Azonosítás, a felhasználói fiókba történő biztonságos belépést szolgálja.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>E-mail cím</Tcol>
                                            <Tcol>Kapcsolattartás, rendszerüzenetek küldése, felhasználói fiókba történő belépés</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Értesítési telefonszám</Tcol>
                                            <Tcol>Kapcsolattartás szállítással kapcsolatos információk közlése.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>A feliratkozás időpontja hírlevél esetében</Tcol>
                                            <Tcol>Technikai művelet végrehajtása.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>A feliratkozás kori IP cím</Tcol>
                                            <Tcol>Technikai művelet végrehajtása.</Tcol>
                                        </tr>
                                    </tbody>
                                </Table>
                                </li>
                                <li>Az érintettek köre: A hírlevélre feliratkozó és DM kiadványokat igénylő valamennyi érintett.</li>
                                <li>Az adatkezelés célja: reklámot tartalmazó elektronikus üzenetek (e-mail, sms, push üzenet) küldése az érintett részére, valamint elektronikus, telemarketinges és postai úton történő tájékoztatás nyújtása az aktuális információkról, termékekről, akciókról, új funkciókról stb.</li>
                                <li>Az adatkezelés időtartama, az adatok törlésének határideje: a hozzájáruló nyilatkozat visszavonásáig, azaz a leiratkozásig tart az adatkezelés.</li>
                                <li>Az adatok megismerésére jogosult lehetséges adatkezelők személye, a személyes adatok címzettjei: A személyes adatokat az adatkezelő sales és marketing munkatársai kezelhetik, a fenti alapelvek tiszteletben tartásával.</li>
                                <li>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése:<br />
                                    <Lists.ul>
                                        <li>Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, valamint</li>
                                        <li>tiltakozhat a személyes adatainak kezelése ellen és</li>
                                        <li>az érintettnek joga van az adathordozhatósághoz, továbbá a hozzájárulás bármely időpontban történő visszavonásához.</li>
                                    </Lists.ul><br />
                                    Tájékoztatjuk az érintettet, hogy a hozzájárulását bármikor visszavonhatja a GDPR 7. cikk 3) bekezdés alapján.
                                </li>
                                <li>A személyes adatokhoz való hozzáférést, azok törlését, módosítását, vagy kezelésének korlátozását, az adatok hordozhatóságát, illetve tiltakozását az alábbi módokon tudja érintett kezdeményezni:<br />
                                - postai úton a 1024 Budapest, Margit körút 5. A. ép. 3. em. 1 címen,<br/>
                                - e-mail útján az adatkezeles@ifaktor.hu e-mail címen,<br/>
                                - telefonon a +36 20 516 9448 számon.
                                </li>
                                <li>Az érintett bármikor, ingyenesen leiratkozhat a hírlevélről, postai DM küldeményekről, a tájékoztató hívásokról lemondhat.</li>
                                <li>Az adatkezelés jogalapja: az érintett hozzájárulása, 6. cikk (1) bekezdés a) és f) pont és a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008. évi XLVIII. törvény 6. § (5) bekezdése:<br />
                                    <i>A reklámozó, a reklámszolgáltató, illetve a reklám közzétevője – a hozzájárulásban meghatározott körben - a náluk hozzájáruló nyilatkozatot tevő személyek személyes adatairól nyilvántartást vezet. Az ebben a nyilvántartásban rögzített - a reklám címzettjére vonatkozó - adat csak a hozzájáruló nyilatkozatban foglaltaknak megfelelően, annak visszavonásáig kezelhető, és harmadik fél számára kizárólag az érintett személy előzetes hozzájárulásával adható át.</i>
                                </li>
                                <li>Tájékoztatjuk, hogy<br />
                                <Lists.ul>
                                    <li>az adatkezelés az Ön hozzájárulásán és a szolgáltató jogos érdekén alapul.</li>
                                    <li>köteles a személyes adatokat megadni, ha hírlevelet, postai DM-et, telefonos értesítéseket szeretne kapni tőlünk.</li>
                                    <li>az adatszolgáltatás elmaradása azzal a következményekkel jár, hogy nem tudunk Önnek hírlevelet, postai küldeményeket küldeni, telefonhívás keretein belül tájékoztatni.</li>
                                </Lists.ul>
                                </li>
                            </Lists.ol>
                        </Text>

                        <SmallPrivacyTitle>4.3. Panaszkezelés:</SmallPrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li> Az adatgyűjtés ténye, a kezelt adatok köre és az adatkezelés célja:<br />
                                <Table>
                                    <thead>
                                        <tr>
                                            <ThCol>Személyes adat</ThCol>
                                            <ThCol>Az adatkezelés célja</ThCol>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <Tcol>Vezetéknév, keresztnév</Tcol>
                                            <Tcol>Azonosítás, a felhasználói fiókba történő biztonságos belépést szolgálja.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>E-mail cím</Tcol>
                                            <Tcol>Kapcsolattartás.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Telefonszám</Tcol>
                                            <Tcol>Kapcsolattartás.</Tcol>
                                        </tr>
                                        <tr>
                                            <Tcol>Számlázási név és cím</Tcol>
                                            <Tcol>Azonosítás, a megrendelt termékekkel kapcsolatosan felmerülő minőségi kifogások, kérdések és problémák kezelése.</Tcol>
                                        </tr>
                                    </tbody>
                                </Table>
                                </li>
                                <li>Az érintettek köre: A weboldalon vásárló, vagy előfizető és minőségi kifogással élő, panaszt tevő valamennyi érintett.</li>
                                <li>Az adatkezelés időtartama, az adatok törlésének határideje: A kifogásról felvett jegyzőkönyv, átirat és az arra adott válasz másolati példányait a fogyasztóvédelemről szóló 1997. évi CLV. törvény 17/A. § (7) bekezdése alapján 5 évig kell megőrizni.</li>
                                <li>Az adatok megismerésére jogosult lehetséges adatkezelők személye, a személyes adatok címzettjei: A személyes adatokat az adatkezelő sales és marketing munkatársai kezelhetik, a fenti alapelvek tiszteletben tartásával.</li>
                                <li>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése:<br />
                                <Lists.ul>
                                    <li>Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatokhoz való hozzáférést, azok helyesbítését, törlését vagy kezelésének korlátozását, és</li>
                                    <li>az érintettnek joga van az adathordozhatósághoz, továbbá a hozzájárulás bármely időpontban történő visszavonásához.</li>
                                </Lists.ul>
                                </li>
                                <li>A személyes adatokhoz való hozzáférést, azok törlését, módosítását, vagy kezelésének korlátozását, az adatok hordozhatóságát az alábbi módokon tudja érintett kezdeményezni:<br />
                                - postai úton a 1024 Budapest, Margit körút 5. A. ép. 3. em. 1. címen,<br />
                                - e-mail útján az adatkezeles@ifaktor.hu e-mail címen,<br />
                                - telefonon a +36 20 516 9448 számon.
                                </li>
                                <li>Az adatkezelés jogalapja: a GDPR 6. cikk (1) bekezdés c) pont és a fogyasztóvédelemről szóló 1997. évi CLV. törvény 17/A. § (7) bekezdése.</li>
                                <li>Tájékoztatjuk, hogy<br />
                                <Lists.ul>
                                    <li>a személyes adat szolgáltatása jogi kötelezettségen alapul.</li>
                                    <li>a szerződés megkötésének előfeltétele a személyes adatok kezelése.</li>
                                    <li>köteles a személyes adatokat megadni, hogy panaszát kezelni tudjuk.</li>
                                    <li>az adatszolgáltatás elmaradása azzal a következményekkel jár, hogy nem tudjuk kezelni a hozzánk beérkezett panaszát.</li>
                                </Lists.ul>
                                </li>
                            </Lists.ol>
                        </Text>

                        <PrivacyTitle>5. Címzettek, akikkel a személyes adatokat közlik</PrivacyTitle>
                        <SmallPrivacyTitle>5.1. Adatfeldolgozók (akik, az adatkezelő nevében végzik az adatfeldolgozást)</SmallPrivacyTitle>
                        <Text>Az adatkezelő a saját adatkezelési tevékenységeinek elősegítése céljából, továbbá érintettel kötött szerződés-, illetve a jogszabályok által támasztott kötelezettségeinek teljesítése érdekében adatfeldolgozókat vesz igénybe.</Text>
                        <Text>Az adatkezelő nagy hangsúlyt fektet arra, hogy kizárólag olyan adatfeldolgozókat vegyen igénybe, akik vagy amelyek megfelelő garanciákat nyújtanak az adatkezelés GDPR-ban foglalt követelményeinek való megfelelését és az érintettek jogainak védelmét biztosító, megfelelő technikai és szervezési intézkedések végrehajtására.</Text>
                        <Text>Az adatfeldolgozó és bármely, az adatkezelő vagy az adatfeldolgozó irányítása alatt eljáró, a személyes adatokhoz hozzáféréssel rendelkező személy a jelen szabályzatban foglalt személyes adatokat kizárólag az adatkezelő utasításának megfelelően kezeli.</Text>
                        <Text>Az adatfeldolgozó tevékenységeiért az adatkezelő jogi felelősséggel tartozik. Az adatfeldolgozó csak abban az esetben tartozik felelősséggel az adatkezelés által okozott károkért, ha nem tartotta be a GDPR-ban meghatározott, kifejezetten az adatfeldolgozókat terhelő kötelezettségeket, vagy ha az adatkezelő jogszerű utasításait figyelmen kívül hagyta, vagy azokkal ellentétesen járt el.</Text>
                        <Text>Az adatfeldolgozónak az adatok kezelését érintően érdemi döntéshozatala nincsen.</Text>
                        <Text>Az adatkezelő az informatikai háttár biztosítására tárhely-szolgáltatót, a megrendelt termékek kiszállítására futárszolgálatot, mint adatfeldolgozót vesz igénybe.</Text>

                        <SmallPrivacyTitle>5.2. Egyes Adatfeldolgozók</SmallPrivacyTitle>
                        <Table>
                            <thead>
                                <tr>
                                    <ThCol three>Tevékenység</ThCol>
                                    <ThCol three>Neve, Elérhetősége</ThCol>
                                    <ThCol three>Feldolgozott Adatok Köre</ThCol>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Tcol three>Jogi szolgáltatás</Tcol>
                                    <Tcol three>Várnai és Társa Ügyvédi Iroda (1026 Budapest, Pasaréti út 25. A. ép. II/1.)</Tcol>
                                    <Tcol three>vezetéknév, keresztnév, lakcím, telefonszám, e-mail cím</Tcol>
                                </tr>
                                <tr>
                                    <Tcol three>Számviteli szolgáltatások</Tcol>
                                    <Tcol three>HG BackOffice Kft. (1024 Budapest, Margit körút 5. A. ép. 3. em. 1.) FINACONT Kft. (1062 Budapest, Aradi utca 16. II/2.)</Tcol>
                                    <Tcol three>számlázási név, számlázási cím, telefonszám, e-mail cím</Tcol>
                                </tr>
                                <tr>
                                    <Tcol three>Weboldal üzemeltetés</Tcol>
                                    {/* Netlify, Inc. (2325 3rd Street, Suite 296, San Francisco, California 94107), Stricca Adriano e.v. (1023 Budapest, Vérhalom utca 12-16.) */}
                                    <Tcol three>„Future of Media” Kft. (3700 Kazincbarcika, Paál László utca 17.), ACtive-IT Kft. (1086 Budapest, Csobánc utca 8. I/7.), Inclust Systems Kft. (1054 Budapest, Honvéd utca 8. I/2.)</Tcol>
                                    <Tcol three>vezetéknév, keresztnév, lakcím, telefonszám, e-mail cím, születési dátum, IP cím, rögzítés időpontja, számlázási név, számlázási cím, szállítási név, szállítási cím</Tcol>
                                </tr>
                            </tbody>
                        </Table>
                        <Text><i>Az Adatfeldolgozók cégnevére, valamint székhelyére vonatkozó adatok az Adatvédelmi Szabályzat elfogadásának időpontjában hatályos állapotot tükrözi, ily módon Adatkezelő fenntartja magának a jogot arra, hogy az Adatfeldolgozók adataiban bekövetkezett változás esetén azokat módosítsa, akként, hogy ennek elmulasztása esetén az Igazságügyi Minisztérium Céginformációs és Elektronikus Cégeljárásban Közreműködő Szolgálata által vezetett, nyilvános és közhiteles cégnyilvántartásba bejegyzett adatok tekintendők irányadónak.</i></Text>

                        <PrivacyTitle>6. Cookie-k (Sütik) Kezelése</PrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>Webáruházakra jellemző cookie-k az úgynevezett „jelszóval védett munkamenethez használt cookie”, „bevásárlókosárhoz szükséges cookie-k”, „biztonsági cookie-k”, „Szükségszerű cookie-k”, ”Funkcionális cookie-k”, és a „weboldal statisztikájának kezeléséért felelős cookie-k” melyek használatához nem szükséges előzetes hozzájárulást kérni az érintettektől.</li>
                                <li>Az adatkezelés ténye, a kezelt adatok köre: Egyedi azonosítószám, dátumok, időpontok</li>
                                <li>Az érintettek köre: A weboldalt látogató valamennyi érintett.</li>
                                <li>Az adatkezelés célja: A felhasználók azonosítása, a „bevásárlókosár” nyilvántartására és a látogatók nyomon követése.</li>
                                <li>Az adatkezelés időtartama, az adatok törlésének határideje:<br />
                                    <Table>
                                        <thead>
                                            <tr>
                                                <ThCol four>Süti típusa</ThCol>
                                                <ThCol four>Adatkezelés jogalapja</ThCol>
                                                <ThCol four>Adatkezelés időtartama</ThCol>
                                                <ThCol four>Kezelt adatkör</ThCol>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <Tcol four>Munkamenet sütik (session)</Tcol>
                                                <Tcol four>Az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalmi szolgáltatások egyes kérdéseiről szóló 2001. CVIII. törvény (Elkertv.) 13/A. § (3) bekezdése</Tcol>
                                                <Tcol four>A vonatkozó látogatói munkamenet lezárásáig tartó időszak</Tcol>
                                                <Tcol four>connect.sid</Tcol>
                                            </tr>
                                            <tr>
                                                <Tcol four>Állandó vagy mentett sütik</Tcol>
                                                <Tcol four>Az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalmi szolgáltatások egyes kérdéseiről szóló 2001. CVIII. törvény (Elkertv.) 13/A. § (3) bekezdése</Tcol>
                                                <Tcol four>az érintett törléséig</Tcol>
                                                <Tcol four></Tcol>
                                            </tr>
                                            <tr>
                                                <Tcol four>Statisztikai sütik</Tcol>
                                                <Tcol four>Az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalmi szolgáltatások egyes kérdéseiről szóló 2001. CVIII. törvény (Elkertv.) 13/A. § (3) bekezdése</Tcol>
                                                <Tcol four>1-2 hónap</Tcol>
                                                <Tcol four></Tcol>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </li>
                                <li>Az adatok megismerésére jogosult lehetséges adatkezelők személye: A cookie-k használatával nem kezel személyes adatokat az adatkezelő.</li>
                                <li>Az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: Az érintettnek lehetőségük van a cookie-kat törölni a böngészők Eszközök/Beállítások menüjében általában az Adatvédelem menüpont beállításai alatt.</li>
                                <li>Az adatkezelés jogalapja: Az érintettől hozzájárulás nem szükséges, amennyiben a cookie-k használatának kizárólagos célja az elektronikus hírközlő hálózaton keresztül történő közléstovábbítás vagy arra az előfizető vagy felhasználó által kifejezetten kért, az információs társadalommal összefüggő szolgáltatás nyújtásához a szolgáltatónak feltétlenül szüksége van.</li>
                            </Lists.ol>
                        </Text>

                        <PrivacyTitle>7. Google és Facebook Szolgáltatások Használata</PrivacyTitle>

                        <SmallPrivacyTitle>7.1. Google Adwords Konverziókövetés Használata</SmallPrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>A „Google AdWords” nevű online reklámprogramot használja az adatkezelő, továbbá annak keretein belül igénybe veszi a Google konverziókövető szolgáltatását. A Google konverziókövetés a Google Inc. elemző szolgáltatása (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“).</li>
                                <li>Amikor Felhasználó egy weboldalt Google-hirdetés által ér el, akkor egy a konverziókövetéshez szükséges cookie kerül a számítógépére. Ezeknek a cookie-knak az érvényessége korlátozott, és nem tartalmaznak semmilyen személyes adatot, így a Felhasználó nem is azonosítható általuk.</li>
                                <li>Amikor a Felhasználó a weboldal bizonyos oldalait böngészi, és a cookie még nem járt le, akkor a Google és az adatkezelő is láthatja, hogy Felhasználó a hirdetésre kattintott.</li>
                                <li>Minden Google AdWords ügyfél másik cookie-t kap, így azokat az AdWords ügyfeleinek weboldalain keresztül nem lehet nyomon követni.</li>
                                <li>Az információk – melyeket a konverziókövető cookie-k segítségével szereztek – azt a célt szolgálják, hogy az AdWords konverziókövetést választó ügyfeleinek számára konverziós statisztikákat készítsenek. Az ügyfelek így tájékozódnak a hirdetésükre kattintó és konverziókövető címkével ellátott oldalra továbbított felhasználók számáról. Azonban olyan információkhoz nem jutnak hozzá, melyekkel bármelyik felhasználót azonosítani lehetne.</li>
                                <li>Ha nem szeretne részt venni a konverziókövetésben, akkor ezt elutasíthatja azáltal, hogy böngészőjében letiltja a cookie-k telepítésének lehetőségét. Ezután Ön nem fog szerepelni a konverziókövetési statisztikákban.</li>
                                <li>További információ valamint a Google adatvédelmi nyilatkozata az alábbi oldalon érhető el: <Anchor as="a" href="https://www.google.de/policies/privacy/">https://www.google.de/policies/privacy/</Anchor></li>
                            </Lists.ol>
                        </Text>

                        <SmallPrivacyTitle>7.2. Google Analytics Alkalmazása</SmallPrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>Ez a honlap a Google Analytics alkalmazást használja, amely a Google Inc. („Google”) webelemző szolgáltatása. A Google Analytics úgynevezett „cookie-kat”, szövegfájlokat használ, amelyeket a számítógépére mentenek, így elősegítik Felhasználó által látogatott weblap használatának elemzését.</li>
                                <li>A Felhasználó által használt weboldallal kapcsolatos cookie-kkal létrehozott információk rendszerint a Google egyik USA-beli szerverére kerülnek és tárolódnak. Az IP-anonimizálás weboldali aktiválásával a Google a Felhasználó IP-címét az Európai Unió tagállamain belül vagy az Európai Gazdasági Térségről szóló megállapodásban részes más államokban előzőleg megrövidíti.</li>
                                <li>A teljes IP-címnek a Google USA-ban lévő szerverére történő továbbítására és ottani lerövidítésére csak kivételes esetekben kerül sor. Eme weboldal üzemeltetőjének megbízásából a Google ezeket az információkat arra fogja használni, hogy kiértékelje, hogyan használta a Felhasználó a honlapot, továbbá, hogy a weboldal üzemeltetőjének a honlap aktivitásával összefüggő jelentéseket készítsen, valamint, hogy a weboldal- és az internethasználattal kapcsolatos további szolgáltatásokat teljesítsen.</li>
                                <li>A Google Analytics keretein belül a Felhasználó böngészője által továbbított IP-címet nem vezeti össze a Google más adataival. A cookie-k tárolását a Felhasználó a böngészőjének megfelelő beállításával megakadályozhatja, azonban felhívjuk figyelmét, hogy ebben az esetben előfordulhat, hogy ennek a honlapnak nem minden funkciója lesz teljes körűen használható. Megakadályozhatja továbbá, hogy a Google gyűjtse és feldolgozza a cookie-k általi, a Felhasználó weboldalhasználattal kapcsolatos adatait (beleértve az IP-címet is), ha letölti és telepíti a következő linken elérhető böngésző plugint. <Anchor as={"a"} href="https://tools.google.com/dlpage/gaoptout?hl=hu">https://tools.google.com/dlpage/gaoptout?hl=hu</Anchor></li>
                            </Lists.ol>
                        </Text>

                        <SmallPrivacyTitle>7.3. Facebook Pixel</SmallPrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>A Facebook-képpont olyan kód, amelynek a segítségével a honlapon jelentés készül a konverziókról, célközönségek állíthatók össze, és az oldal tulajdonosa részletes elemzési adatokat kap a látogatók honlap használatáról. A Facebook remarketing pixel követőkód segítségével a weboldal látogatóinak személyre szabott ajánlatokat, hirdetéseket jeleníthet meg a Facebook felületén. A Facebook remarketing lista nem alkalmas személyazonosításra. A Facebook Pixellel / Facebook-képponttal kapcsolatosan	további	információt	itt	találhat: <Anchor as="a" href="https://www.facebook.com/business/help/651294705016616">https://www.facebook.com/business/help/651294705016616</Anchor></li>
                            </Lists.ol>
                        </Text>
                        
                        <SmallPrivacyTitle>7.4. Közösségi oldalak</SmallPrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>Az adatgyűjtés ténye, a kezelt adatok köre: Facebook/Google+/Twitter/Pinterest/Youtube/Instagram stb. közösségi oldalakon regisztrált neve, illetve a felhasználó nyilvános profilképe.</li>
                                <li>Az érintettek köre: Valamennyi érintett, aki regisztrált a Facebook/Google+/Twitter/Pinterest/Youtube/Instagram stb. közösségi oldalakon, és „lájkolta” a Szolgáltató közösségi oldalát, illetve a közösségi oldalon keresztül felvette a kapcsolatot az adatkezelővel.</li>
                                <li>Az adatgyűjtés célja: A közösségi oldalakon, a weboldal egyes tartalmi elemeinek, termékeinek, akcióinak vagy magának a weboldalnak a megosztása, illetve „lájkolása”, követése, népszerűsítése.</li>
                                <li>Az adatkezelés időtartama, az adatok törlésének határideje, az adatok megismerésére jogosult lehetséges adatkezelők személye és az érintettek adatkezeléssel kapcsolatos jogainak ismertetése: Az adatok forrásáról, azok kezeléséről, illetve az átadás módjáról, és jogalapjáról az adott közösségi oldalon tájékozódhat az érintett. Az adatkezelés a közösségi oldalakon valósul meg, így az adatkezelés időtartamára, módjára, illetve az adatok törlési és módosítási lehetőségeire az adott közösségi oldal szabályozása vonatkozik.</li>
                                <li>Az adatkezelés jogalapja: az érintett önkéntes hozzájárulása személyes adatai kezeléséhez a közösségi oldalakon.</li>
                            </Lists.ol>
                        </Text>

                        <PrivacyTitle>8. Ügyfélkapcsolatok és Egyéb Adatkezelések</PrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li>Amennyiben az adatkezelő szolgáltatásaink igénybevétele során kérdés merülne fel, esetleg problémája lenne az érintettnek, a honlapon megadott módokon (telefon, e- mail, közösségi oldalak stb.) kapcsolatba léphet az adatkezelővel.</li>
                                <li>Adatkezelő a beérkezett e-maileket, üzeneteket, telefonon, Facebook-on stb. megadott adatokat az érdeklődő nevével és e-mail címével, valamint más, önként megadott személyes adatával együtt, az adatközléstől számított legfeljebb 2 év elteltével törli.</li>
                                <li>E tájékoztatóban fel nem sorolt adatkezelésekről az adat felvételekor adunk tájékoztatást.</li>
                                <li>Kivételes hatósági megkeresésre, illetőleg jogszabály felhatalmazása alapján más szervek megkeresése esetén a Szolgáltató köteles tájékoztatás adására, adatok közlésére, átadására, illetőleg iratok rendelkezésre bocsátására.</li>
                                <li>A Szolgáltató ezen esetekben a megkereső részére – amennyiben az a pontos célt és az adatok körét megjelölte – személyes adatot csak annyit és olyan mértékben ad ki, amely a megkeresés céljának megvalósításához elengedhetetlenül szükséges.</li>
                            </Lists.ol>
                        </Text>

                        <PrivacyTitle>9. Az Érintettek Jogai</PrivacyTitle>
                        <Text>
                            <Lists.ol>
                                <li><b>A hozzáférés joga</b><br />
                                    Ön jogosult arra, hogy az adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és a rendeletben felsorolt információkhoz hozzáférést kapjon.
                                </li>
                                <li><b>A helyesbítéshez való jog</b><br />
                                    Ön jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat. Figyelembe véve az adatkezelés célját, Ön jogosult arra, hogy kérje a hiányos személyes adatok – egyebek mellett kiegészítő nyilatkozat útján történő – kiegészítését.
                                </li>
                                <li><b>A törléshez való jog</b><br />
                                    Ön jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, az adatkezelő pedig köteles arra, hogy Önre vonatkozó személyes adatokat indokolatlan késedelem nélkül törölje meghatározott feltételek esetén.
                                </li>
                                <li><b>Az elfeledtetéshez való jog</b><br />
                                    Ha az adatkezelő nyilvánosságra hozta a személyes adatot, és azt törölni köteles, az elérhető technológia és a megvalósítás költségeinek figyelembevételével megteszi az ésszerűen elvárható lépéseket – ideértve technikai intézkedéseket – annak érdekében, hogy tájékoztassa az adatokat kezelő adatkezelőket, hogy Ön kérelmezte a szóban forgó személyes adatokra mutató linkek vagy e személyes adatok másolatának, illetve másodpéldányának törlését.
                                </li>
                                <li><b>Az adatkezelés korlátozásához való jog</b><br />
                                Ön jogosult arra, hogy kérésére az adatkezelő korlátozza az adatkezelést, ha az alábbi feltételek valamelyike teljesül:<br />
                                <Lists.ul>
                                    <li>Ön vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az adatkezelő ellenőrizze a személyes adatok pontosságát;</li>
                                    <li>az adatkezelés jogellenes, és Ön ellenzi az adatok törlését, és ehelyett kéri azok felhasználásának korlátozását;</li>
                                    <li>az adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de Ön igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez;</li>
                                    <li>Ön tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az adatkezelő jogos indokai elsőbbséget élveznek-e Ön jogos indokaival szemben.</li>
                                </Lists.ul>
                                </li>
                                <li><b>Az adathordozhatósághoz való jog</b><br />
                                Ön jogosult arra, hogy a rá vonatkozó, általa egy adatkezelő rendelkezésére bocsátott személyes adatokat tagolt, széles körben használt, géppel olvasható formátumban megkapja, továbbá jogosult arra, hogy ezeket az adatokat egy másik adatkezelőnek továbbítsa anélkül, hogy ezt akadályozná az az adatkezelő, amelynek a személyes adatokat a rendelkezésére bocsátotta (...)
                                </li>
                                <li><b>A tiltakozáshoz való jog</b><br />
                                A jogos érdeken, illetve a közhatalmi jogosítványon, mint jogalapokon alapuló adatkezelések esetében Ön jogosult arra, hogy a saját helyzetével kapcsolatos okokból bármikor tiltakozzon személyes adatainak a (...) kezelése ellen, ideértve az említett rendelkezéseken alapuló profilalkotást is.
                                </li>
                                <li><b>Tiltakozás közvetlen üzletszerzés estén</b><br />
                                Ha a személyes adatok kezelése közvetlen üzletszerzés érdekében történik, Ön jogosult arra, hogy bármikor tiltakozzon a rá vonatkozó személyes adatok e célból történő kezelése ellen, ideértve a profilalkotást is, amennyiben az a közvetlen üzletszerzéshez kapcsolódik. Ha Ön tiltakozik a személyes adatok közvetlen üzletszerzés érdekében történő kezelése ellen, akkor a személyes adatok a továbbiakban e célból nem kezelhetők.
                                </li>
                                <li><b>Automatizált döntéshozatal egyedi ügyekben, beleértve a profilalkotást</b><br />
                                Ön jogosult arra, hogy ne terjedjen ki rá az olyan, kizárólag automatizált adatkezelésen – ideértve a profilalkotást is – alapuló döntés hatálya, amely rá nézve joghatással járna vagy őt hasonlóképpen jelentős mértékben érintené.<br />
                                Az előző bekezdés nem alkalmazandó abban az esetben, ha a döntés:
                                <Lists.ul>
                                    <li>Ön és az adatkezelő közötti szerződés megkötése vagy teljesítése érdekében szükséges;</li>
                                    <li>meghozatalát az adatkezelőre alkalmazandó olyan uniós vagy tagállami jog teszi lehetővé, amely Ön jogainak és szabadságainak, valamint jogos érdekeinek védelmét szolgáló megfelelő intézkedéseket is megállapít; vagy</li>
                                    <li>Ön kifejezett hozzájárulásán alapul.</li>
                                </Lists.ul>
                                </li>
                            </Lists.ol>
                        </Text>

                        <PrivacyTitle>10 Intézkedési Határidő</PrivacyTitle>
                        <Text>
                        Az adatkezelő indokolatlan késedelem nélkül, de mindenféleképpen a kérelem beérkezésétől számított 1 hónapon belül tájékoztatja Önt a fenti kérelmek nyomán hozott intézkedésekről.
                        </Text>
                        <Text>
                        Szükség esetén ez 2 hónappal meghosszabbítható. A határidő meghosszabbításáról az adatkezelő a késedelem okainak megjelölésével a kérelem kézhezvételétől számított 1 hónapon belül tájékoztatja Önt.
                        </Text>
                        <Text>
                        Ha az adatkezelő nem tesz intézkedéseket Ön kérelme nyomán, késedelem nélkül, de legkésőbb a kérelem beérkezésétől számított egy hónapon belül tájékoztatja Önt az intézkedés elmaradásának okairól, valamint arról, hogy Ön panaszt nyújthat be valamely felügyeleti hatóságnál, és élhet bírósági jogorvoslati jogával.
                        </Text>

                        <PrivacyTitle>11. Az Adatkezelés Biztonsága</PrivacyTitle>
                        <Text>
                        Az adatkezelő és az adatfeldolgozó a tudomány és technológia állása és a megvalósítás költségei, továbbá az adatkezelés jellege, hatóköre, körülményei és céljai, valamint a természetes személyek jogaira és szabadságaira jelentett, változó valószínűségű és súlyosságú kockázat figyelembevételével megfelelő technikai és szervezési intézkedéseket hajt végre annak érdekében, hogy a kockázat mértékének megfelelő szintű adatbiztonságot garantálja, ideértve, többek között, adott esetben:
                        </Text>
                        <Text>
                            <Lists.ul>
                                <li>a személyes adatok álnevesítését és titkosítását;</li>
                                <li>a személyes adatok kezelésére használt rendszerek és szolgáltatások folyamatos bizalmas jellegének biztosítását, integritását, rendelkezésre állását és ellenálló képességét;</li>
                                <li>fizikai vagy műszaki incidens esetén az arra való képességet, hogy a személyes adatokhoz való hozzáférést és az adatok rendelkezésre állását kellő időben vissza lehet állítani;</li>
                                <li>az adatkezelés biztonságának garantálására hozott technikai és szervezési intézkedések hatékonyságának rendszeres tesztelésére, felmérésére és értékelésére szolgáló eljárást.</li>
                                <li>A papír alapú adathordozókat iratmegsemmisítő segítségével, vagy külső, iratmegsemmisítésre szakosodott szervezet igénybevételével kell a személyes adatoktól megfosztani. Elektronikus adathordozók esetében az elektronikus adathordozók selejtezésére vonatkozó szabályok szerint kell gondoskodni a fizikai megsemmisítésről, illetve szükség szerint előzetesen az adatoknak a biztonságos és visszaállíthatatlan törléséről.<br/>
                                    Az adatkezelő az alábbi konkrét adatbiztonsági intézkedéseket teszi:<br/>
                                    A papír alapon kezelt személyes adatok biztonsága érdekében a Szolgáltató az alábbi intézkedéseket alkalmazza (fizikai védelem):<br/>
                                    A dokumentumokat biztonságos, jól zárható száraz helyiségben helyezni el.<br/>
                                    A Szolgáltató épülete és helyiségei tűzvédelmi és vagyonvédelmi berendezéssel vannak ellátva.<br/>
                                    A személyes adatokat csak az arra jogosult személyek ismerhetik meg, azokhoz harmadik személyek nem férhetnek hozzá.<br/>
                                    A Szolgáltató adatkezelést végző munkatársa a munkavégzése során csak úgy hagyhatja el azt a helyiséget, ahol adatkezelés folyik, hogy a rá bízott adathordozókat elzárja, vagy az adott helyiséget bezárja.<br/>
                                    Amennyiben a papíralapon kezelt személyes adatok digitalizálására kerül sor, akkor a digitálisan tárolt dokumentumokra irányadó szabályokat kell alkalmazni.<br/>
                                    Informatikai védelem<br/>
                                    <Lists.ul>
                                    <li>Az adatkezelés során használt számítógépek és mobil eszközök (egyéb adathordozók) a Szolgáltató birtokát képezik.</li>
                                    <li>A számítógépeken található adatokhoz felhasználónévvel és jelszóval lehet csak hozzáférni.</li>
                                    <li>A központi szerver géphez csak megfelelő jogosultsággal és csakis az arra kijelölt személyek férhetnek hozzá.</li>
                                    <li>A digitálisan tárolt adatok	biztonsága érdekében a Szolgáltató adatmentéseket és archiválásokat alkalmaz.</li>
                                    <li>A Szolgáltató által hasznát személyes adatokat tartalmazó számítógépes rendszer vírusvédelemmel van ellátva.</li>
                                    </Lists.ul>
                                </li>
                            </Lists.ul>
                        </Text>

                        <PrivacyTitle>12. Az Érintett Tájékoztatása az Adatvédelmi Incidensről</PrivacyTitle>
                        <Text>
                            Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár a természetes személyek jogaira és szabadságaira nézve, az adatkezelő indokolatlan késedelem nélkül tájékoztatja az érintettet az adatvédelmi incidensről.
                        </Text>
                        <Text>
                            Az érintett részére adott tájékoztatásban világosan és közérthetően ismertetni kell az adatvédelmi incidens jellegét, és közölni kell az adatvédelmi tisztviselő vagy a további tájékoztatást nyújtó egyéb kapcsolattartó nevét és elérhetőségeit; ismertetni kell az adatvédelmi incidensből eredő, valószínűsíthető következményeket; ismertetni kell az adatkezelő által az adatvédelmi incidens orvoslására tett vagy tervezett intézkedéseket, beleértve adott esetben az adatvédelmi incidensből eredő esetleges hátrányos következmények enyhítését célzó intézkedéseket.
                        </Text>
                        <Text>
                        Az érintettet nem kell tájékoztatni, ha a következő feltételek bármelyike teljesül:<br />
                        <Lists.ul>
                            <li>az adatkezelő megfelelő technikai és szervezési védelmi intézkedéseket hajtott végre, és ezeket az intézkedéseket az adatvédelmi incidens által érintett adatok tekintetében alkalmazták, különösen azokat az intézkedéseket – mint például a titkosítás alkalmazása –, amelyek a személyes adatokhoz való hozzáférésre fel nem jogosított személyek számára értelmezhetetlenné teszik az adatokat;</li>
                            <li>az adatkezelő az adatvédelmi incidenst követően olyan további intézkedéseket tett, amelyek biztosítják, hogy az érintett jogaira és szabadságaira jelentett, magas kockázat a továbbiakban valószínűsíthetően nem valósul meg;</li>
                            <li>a tájékoztatás aránytalan erőfeszítést tenne szükségessé. Ilyen esetekben az érintetteket nyilvánosan közzétett információk útján kell tájékoztatni, vagy olyan hasonló intézkedést kell hozni, amely biztosítja az érintettek hasonlóan hatékony tájékoztatását.</li>
                        </Lists.ul>
                        Ha az adatkezelő még nem értesítette az érintettet az adatvédelmi incidensről, a felügyeleti hatóság, miután mérlegelte, hogy az adatvédelmi incidens valószínűsíthetően magas kockázattal jár-e, elrendelheti az érintett tájékoztatását.
                        </Text>

                        <PrivacyTitle>13. Adatvédelmi Incidens Bejelntése a Hatóságnak</PrivacyTitle>
                        <Text>
                            Az adatvédelmi incidenst az adatkezelő indokolatlan késedelem nélkül, és ha lehetséges, legkésőbb 72 órával azután, hogy az adatvédelmi incidens a tudomására jutott, bejelenti az 55. cikk alapján illetékes felügyeleti hatóságnak, kivéve, ha az adatvédelmi incidens valószínűsíthetően nem jár kockázattal a természetes személyek jogaira és szabadságaira nézve. Ha a bejelentés nem történik meg 72 órán belül, mellékelni kell hozzá a késedelem igazolására szolgáló indokokat is.
                        </Text>

                        <PrivacyTitle>14. Panasztételi Lehetőség</PrivacyTitle>
                        <Text>
                            Az adatkezelő esetleges jogsértése ellen panasszal a Nemzeti Adatvédelmi és Információszabadság Hatóságnál lehet élni:<br />
                            <b>Nemzeti Adatvédelmi és Információszabadság Hatóság</b><br />
                            1125 Budapest, Szilágyi Erzsébet fasor 22/C. Levelezési cím: 1530 Budapest, Postafiók: 5.<br />
                            Telefon: +36 -1-391-1400<br />
                            Fax: +36-1-391-1410<br />
                            E-mail: ugyfelszolgalat@naih.hu
                        </Text>

                        <PrivacyTitle>15. Zárszó</PrivacyTitle>
                        <Text>A tájékoztató elkészítése során figyelemmel voltunk az alábbi jogszabályokra:<br />
                            <Lists.ul>
                                <li>A természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános adatvédelmi rendelet) AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016. április 27.)</li>
                                <li>2011. évi CXII. törvény – az információs önrendelkezési jogról és az információszabadságról (a továbbiakban: Infotv.)</li>
                                <li>2001. évi CVIII. törvény – az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről (főképp a 13/A. §-a)</li>
                                <li>2008. évi XLVII. törvény – a fogyasztókkal szembeni tisztességtelen kereskedelmi gyakorlat tilalmáról;</li>
                                <li>2008. évi XLVIII. törvény – a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól (különösen a 6.§-a)</li>
                                <li>2005. évi XC. törvény az elektronikus információszabadságról</li>
                                <li>2003. évi C. törvény az elektronikus hírközlésről (kifejezetten a 155.§-a)</li>
                                <li>16/2011. sz. vélemény a viselkedésalapú online reklám bevált gyakorlatára vonatkozó EASA/IAB-ajánlásról</li>
                                <li>A Nemzeti Adatvédelmi és Információszabadság Hatóság ajánlása az előzetes tájékoztatás adatvédelmi követelményeiről</li>
                                <li>Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016. április 27.) a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül helyezéséről</li>
                            </Lists.ul>
                        </Text>
                        
                        <SmallPrivacyTitle>
                            2021. február 9.<br />
                            HG PRISMA Korlátolt Felelősségű Társaság 
                        </SmallPrivacyTitle>

                    </TextSection>
                </Wrapper>
        </Transitions.CSS.FadeIn>
    </Layout>
);

const spanColorAccent = p => css`
    span {
        color: ${p.theme.colors.secondaryAccent};
    }
`;

const Title = styled.h1`
    ${tw`mt-12 text-4xl font-semibold leading-none tracking-tight md:mt-0 md:text-6xl text-center mx-auto`};
    ${spanColorAccent};
`;

const Text = styled.p`
    ${tw`my-8 text-base leading-7 text-left text-gray-900`};
`;

const PrivacyTitle = styled.h2`
    ${tw`text-2xl font-semibold leading-none tracking-tight mt-6 md:mt-0 md:text-4xl`};
`;

const SmallPrivacyTitle = styled.h3`
    ${tw`text-xl font-semibold leading-none tracking-tight mt-6 md:mt-4 md:text-3xl`};
`;

const TextSection = styled.div`
    ${tw`max-w-4xl mt-12 mx-auto`};
`;

const FlexBox = styled.div`
    ${tw`items-center justify-center flex-1 md:flex`};
`;

const Table = styled.table`
    ${tw`table-fixed border-collapse mt-4`};
`;

const ThCol = styled.th<{three?: boolean; four?: boolean}>`
    ${tw`w-1/2 border p-2`};
    ${p => p.three && tw`w-1/3`};
    ${p => p.four && tw`w-1/4`};
`;

const Tcol = styled.td<{three?: boolean; four?: boolean}>`
    ${tw`w-1/2 border p-2`};
    ${p => p.three && tw`w-1/3`};
    ${p => p.four && tw`w-1/4`};
`;

const Box = styled.div`
    width: 100%;

    ${mq.from_tablet_ipad`
        width: 70%;
        margin: 20px;
        &:first-of-type {
            margin-left: 0;
        }
    
        &:last-of-type {
            margin-right: 0;
        }
    `};

    ${mq.from_laptop`
        flex: 1 1 0;
        width: 70%;
    `};
`;

export default NotFoundPage;